import React from 'react';
import { FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
const Footer = () => {
  return (
    <footer className="bg-primary text-white p-4 mt-auto">
      <div className="container mx-auto flex flex-wrap justify-center">
        {/* First Row - Unternehmen */}
        <div className="flex flex-col md:flex-row items-center md:mr-8 mb-4 md:mb-0">
          <span className="mr-4">Unternehmen:</span>
          <a href="/impressum" className="mr-2">Impressum</a>
          <a href="/agb" className="mr-2">AGB</a>
          <a href="/widerrufsbelehrung" className="mr-2">Widerrufsbelehrung</a>
          <a href="/datenschutzerklärung">Datenschutzerklärung</a>
        </div>

        {/* Second Row - Projects */}
        <div className="flex items-center md:mr-8 mb-4 md:mb-0">
          <span className="mr-2">Projects:</span>
          <a href="/more-projects">More Projects</a>
        </div>

        {/* Third Row - Contact */}
        <div className="flex items-center">
          <span className="mr-2">Contact:</span>
          <a href="mailto:office@qmwebtech.com" className="mr-2" aria-label="Email">
            <GoMail />
          </a>
          <a href="https://www.linkedin.com/in/qerim-mehmeti-43b888247/?locale=en_US" className="mr-2" rel="noopener noreferrer">
            <FaLinkedinIn />
          </a>
          <a href="https://www.instagram.com/qmqerim" className="mr-2">
            <FaInstagram />
          </a>
          <a href="https://www.twitter.com">
            <FaTwitter />
          </a>
        </div>
      </div>
      <div className="container mx-auto flex justify-center">
        <span>&copy; 2023 QMWEBTECH</span>
      </div>
    </footer>
  );
};

export default Footer;

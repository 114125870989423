import React from 'react';
//import icons
import { GoHome, GoPerson, GoBook, GoCode, GoCalendar } from 'react-icons/go';
// link
import { Link } from 'react-scroll';
const Nav = () => {
  return (
    <nav className="fixed bottom-2 1g:bottom-8 w-full overflow-hidden z-50">
      <div className="container mx-auto">
        {/* nav inner */}
        <div className='w-full bg-black/20 h-[96px] backdrop-blur-2x1 rounded-full max-w-[460px] mx-auto px-5 flex justify-between items-center text2x1 text-white/50'>
          <Link
            to='home'
            activeClass='active'
            smooth={true}
            spy={true}
            className="cursor-pointer w-[60px] h-[60px] 
            flex items-center justify-center"
            offset={-200}
          >
            <GoHome />
          </Link>
          <Link
            to='about'
            activeClass='active'
            smooth={true}

            spy={true}
            className="cursor-pointer w-[60px] h-[60px] 
            flex items-center justify-center"
          >
            <GoPerson />
          </Link>
          <Link
            to='services'
            activeClass='active'
            smooth={true}
            spy={true}
            className="cursor-pointer w-[60px] h-[60px] 
            flex items-center justify-center"
          >
            <GoBook />
          </Link>
          <Link
            to='work'
            activeClass='active'
            smooth={true}
            spy={true}
            className="cursor-pointer w-[60px] h-[60px] 
            flex items-center justify-center"
          >
            <GoCode />
          </Link>
          <Link
            to='contact'
            activeClass='active'
            smooth={true}
            spy={true}
            className="cursor-pointer w-[60px] h-[60px] 
             flex items-center justify-center"
          >
            <GoCalendar />
          </Link>

        </div>
      </div>
    </nav>
  );
};

export default Nav;

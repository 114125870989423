import React from 'react';


//motion 
import { motion } from 'framer-motion';

//variables
import { fadeIn } from '../variants';

//services date
const services = [
  {
    name: 'UI/UX Design',
    description:
      "Unser professionelles Team bietet morderne Website-Design-, Redesign- und E-Commerce-Lösungen, die auf Ihre Geschäftsanforderungen zugeschnitten sind.",
    link: 'Mehr dazu.',
  },
  {
    name: 'Development',
    description:
      "Unser Team setzt sich dafür ein, dass Ihre Website nicht nur optisch ansprechend, sondern auch funktional, benutzerfreundlich und für Suchmaschinen optimiert ist.",
    link: 'Mehr dazu.',
  },
  {
    name: 'Help & Support',
    description:
      "QMWebTech bietet erschwingliche Website-Design-, Redesign- und E-Commerce-Lösungen, die auf Ihre Geschäftsanforderungen zugeschnitten sind und rund um Support",
    link: 'Mehr dazu.',
  },

];


const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row '>


          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'>
            {/* services list*/}
            <div>
              {services.map((service, index) => {
                //destructure service
                const { name, description, link } = service;
                return (
                  <div className='border-b border-white/20 h-[156px] mb-[38px] flex'
                    key={index}>
                    <div className='max-w[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                        {name}
                      </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                      </p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                    </div>
                  </div>
                )
              })}
            </div>
          </motion.div>
          {/*text & image*/}
          <motion.div
            variants={fadeIn('right', 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="ml-10 flex-1 lg:bg-services bg-top bg-no-repeat mix-blend-lighten mb-20 lg:mb-0 bg-contain hidden lg:block"
          >
            <h2 className="h2 to-accent mb-6 mx-16 text-right">What I do</h2>
            <h3 className="h3 max-w-[455px] mx-16 text-right">
              Freelancers with 3 years of Experience.
            </h3>
          
          </motion.div>



        </div>
      </div>
    </section>
  );
};

export default Services;

import React from 'react';
//images
import videoFile from '../assets/logovid.mp4';

//icons 
import { FaWhatsapp, FaGithub, FaInstagram } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
//type animation
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '..//variants';


const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center'
      id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>

          {/* text*/}
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
              QM<span>WEBTECH</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-4'> WE ARE</span>
              <TypeAnimation
                sequence={[
                  'Front-End-Developer',
                  2000,
                  'UI/UX Designer',
                  2000,
                  'YOUR NEXT PARTNER!',
                  2000,
                ]}
                speed={50}
                className='text-transparent bg-gradient-to-r from-[#00B4DB] via-[#20BDFF] to-[#0083B0] bg-clip-text'
                wrapper='span'  
                repeat={Infinity}
              />


            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='font-fourth mb-8 max-w-lg mx-auto lg:mx-0'>
              Auf der Suche nach einer modernen, schnellen und sicheren Webseite?
              Dann Kontaktieren Sie uns jetzt!
            </motion.p>
            <motion.div
              variants={fadeIn('up', 0.6)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
              <button className='btn btn-lg'>
                <a href='#contact'>Contact US
                </a>
              </button>

            </motion.div>
            {/*sociaals */}
            <motion.div
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='banner flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
              <a href="https://www.instagram.com/qmqerim/" className="icon instagram">
                <span className="tooltip">Instagram</span>
                <FaInstagram />
              </a>
              <a href="tel:+491731004974" className="icon whatsapp">
                <span className="tooltip">WhatsApp</span>
                <FaWhatsapp />
              </a>
              <a href="https://github.com/GitHubQerim" className="icon github">
                <span className="tooltip">Github</span>
                <FaGithub />
              </a>
              <a href="mailto:office@qmwebtech.com" className="icon gmail">
                <span className="tooltip">Gmail</span>
                <SiGmail />
              </a>
            </motion.div>
          </div>

          {/*image*/}
          <motion.div variants={fadeIn('up', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.7 }}
            className='hidden lg:flex flex-1 max-w-[360px] lg:max-w[482px] mx-auto'>
            <video src={videoFile} autoPlay muted />
          </motion.div>
        </div>


      </div>
    </section>
  );
};

export default Banner;

import React, { useState } from 'react';
import contact from '../data';
import { useFormik } from 'formik';
import * as Yup from 'yup';



const Contact = () => {

  //Router logic

  //Formik Logic
  const formik = useFormik({
    initialValues: {
      name: '',
      lastname: '',
      email: '',
      subject: '',
      message: '',

    },
    //validate
    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, 'Name sollte nicht mehr als 20 Buchstaben haben')
        .required('Ein Vorname ist erforderlich!'),
      lastname: Yup.string()
        .max(20, 'Name sollte nicht mehr als 20 Buchstaben haben')
        .required('Ein Nachname ist erforderlich!'),
      email: Yup.string()
        .email('Invalid E-Mail Adresse')
        .required("Ein E-Mail ist erforderlich!"),
      subject: Yup.string()
      .max(20, 'Invalide Zeichen')
      .required('Ihr Anliegen bitte angeben'),
    }),
    //Submit
    onSubmit: (values) => {
      console.log(values);
     
    },
  });

  console.log(formik.values)

  return (
    <section className='section' id='contact'>
      <div className='container mx-auto'>
        {/* section title */}
        <div className='flex flex-col items-center text-center'>
          <h2 className='h2 leading-tight text-gradient'>
            Contact us
          </h2>
          <p className='font-fourth subtitle'>
            Wollen Sie uns schnell kontaktieren,
            dann gerne per Formular, E-Mail, Telefonnummer
            oder auf unseren Sozialen Medien!
          </p>
          <br />
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 lg:gap-x-0'>
          {/* Info */}
          <div className='flex flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2 lg:self-start hidden lg:block'>
            {contact.map((item, index) => {
              const { icons, title, subtitle, description } = item;
              return (
                <div className='flex flex-col lg:flex-row gap-x-4' key={index}>
                  <div className='rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl'>
                    {icons} {/* Render the icon component */}
                  </div>
                  <div>
                    <h4 className='font-body text-xl mb-1'>{title}</h4>
                    <p className='mb-1'>{subtitle}</p>
                    <p className='text-transparent bg-gradient-to-r
                     from-[#5186f1] to-[#2694f4] bg-clip-text font-normal'>
                      {description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Form */}
          <form
            onSubmit={formik.handleSubmit}
            className='w-full max-w-2xl mx-auto lg:ml-auto mt-1 lg:mt-0'>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full md:w-1/2 px-3 mb-2 md:mb-0'>

                <label className={`block uppercase tracking-wide
                 text-gray-700 text-xs font-bold mb-2 ${formik.touched.name && formik.errors.name ? "text-red-400" : ""
                  }`}
                  htmlFor='name'
                >
                  {formik.touched.name && formik.errors.name ? formik.errors.name : "Vorname"}
                </label>

                <input
                  className='appearance-none block w-full bg-gray-200
                 text-gray-700 border rounded py-2 
                 px-3 mb-3 leading-tight focus:outline-none focus:bg-white'
                  type='text'
                  placeholder='Max'
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className='w-full md:w-1/2 px-3'>
                <label
                  className={`block uppercase tracking-wide text-gray-700 
                text-xs font-bold mb-2 
                ${formik.touched.lastname && formik.errors.lastname ? "text-red-400" : ""
                    }`}
                  htmlFor='lastname'>
                  {formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname : "lastname"}
                </label >

                <input
                  className='appearance-none block w-full bg-gray-200 text-gray-700 
                border border-gray-200 rounded py-2 px-3 leading-tight focus:outline-none
                 focus:bg-white focus:border-gray-500'
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  placeholder='Mustermann'
                  onBlur={formik.handleBlur}

                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full px-3'>
                <label
                  className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
                  ${formik.touched.email && formik.errors.email ? "text-red-400" : ""
                    }`}
                  htmlFor='email'
                >
                  {formik.touched.email && formik.errors.email ? formik.errors.email : "E-Mail"}

                </label>

                <input
                  className='textarea appearance-none block w-full bg-gray-200 text-gray-700 border
                 border-gray-200 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white
                  focus:border-gray-500'
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='email'
                  placeholder='max.mustermann@gmail.com' />
              </div>
              <br />

              <div className='w-full px-3'>
                <label
                  className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 
                 ${formik.touched.subject && formik.errors.subject ? "text-red-400" : ""
                    }`}
                  htmlFor='subject'
                  >
                  {formik.touched.subject && formik.errors.subject ? formik.errors.subject : "Thema"}
                </label>

                <input
                  className='textarea appearance-none block w-full bg-gray-200
                 text-gray-700 border border-gray-200 rounded py-2 px-3 
                leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Kontaktaufnahme, Webdesign auffrischen, Kostenanfrage, etc.' />
              </div>

            </div>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full px-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='message'>
                  Wie soll Ihre Webseite aussehen.
                </label>

                <textarea className='textarea block w-full bg-gray-200 text-gray-700 border border-gray-200 
                rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none'
                  id='grid-subject'
                  placeholder='Your message'
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className='flex justify-center flex-wrap -mx-3 mb-5'>
              <button type="submit"
              className='btn btn-lg bg-accent hover:bg-accent-hover' >
              Senden
              </button>
            </div>
          </form>

        </div>
      </div>
    </section>
  );
};

export default Contact;
import { MdEmail, MdLocationCity } from 'react-icons/md';

const contact = [
  {
    icons: <MdEmail />,
    title: 'Email',
    description: 'Email an office@qmwebtech.com',
  },
  {
    icons: <MdLocationCity />,
    title: 'Current, Location',
    subtitle: 'Heilbronn, Germany',
    description: 'Support um den Globus',
  },
  // Additional contact items can be added here
];

export default contact;

import React from 'react';
import WorkCards from './WorkCards';

import test1 from "../assets/DevTeam2.jpg";
import test2 from "../assets/Team1.jpg";
import test3 from "../assets/Team2.jpg";

import Title from "../components/Title"

//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '..//variants';

const Work = () => {
  return (

    <section
      id='work'
      className='w-full py-20 border-b-[1px] border-b-black'>
      <div className='flex justify-center items-center text-center'>
        <Title
          title="Bald sind die Projekte auch einsehbar"
          des="Our Projects"
        />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14'>
        <motion.div
          variants={fadeIn('left', 0.1)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.7 }}>
          <WorkCards
            title="Projekt 1"
            des="Eine mobile Finanzverwaltungs-App für Ausgabenverfolgung und Budgetierung."
            src={test1}
          />

        </motion.div>

        <motion.div
          variants={fadeIn('left', 0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.7 }}>
          <WorkCards
            title="Projekt 2"
            des="Eine Online-Lernplattform für verschiedene Kurse und Schulungen."
            src={test3}
          />

        </motion.div>

        <motion.div
          variants={fadeIn('left', 0.5)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.7 }}>
          <WorkCards
            title="Projekt 3"
            des="Ein intelligentes Home-Automatisierungssystem zur Steuerung von Beleuchtung, Heizung und Haushaltsgeräten."
            src={test2}
          />

        </motion.div>





      </div>
    </section>

  );
};


export default Work;
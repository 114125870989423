import React from 'react';
///Countup
import CountUp from 'react-countup';
// Intersection observer hook
import { useInView } from 'react-intersection-observer';
//motion
import { motion } from 'framer-motion';
//variant
import { fadeIn } from '../variants';


const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className="section" id='about' ref={ref}>
      <div className="container mx-auto">
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen mix-blend-lighten'>
          {/* img */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 bg-about bg-no-repeat bg-top hidden sm:block h-[640px] mix-blend-lighten'>
          </ motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}

            className='flex-1'>
            <h2 className='h2 text-gradient'> About US</h2>
            <h3 className='h3 mb-4'>
              Front-End-Developer with a passion for Design.
            </h3>
            <p className='mb-6'>
              Unsere ambitionierte Programmierteam ist auf die WEB-Entwicklung spezialisiert.
              Wir nutzen die neuesten Technologien und setzen auf enge Zusammenarbeit mit unseren Kunden.
              Unser Ziel ist es, langfristige Partnerschaften aufzubauen und erstklassige Web-Lösungen zu entwickeln.
              Kontaktieren Sie uns um zu erfahren, wie wir am besten kostengünstig ihre Online Präsenz verbessern.
            </p>
            {/*stats*/}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={3} duration={4} /> :
                    null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Jahre an Erfahrung
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={10} duration={1.5} /> :
                    null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Abgeschlossene <br />
                  Projekte
                </div>
              </div>

              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={10} duration={1.5} /> :
                    null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Zufriedene <br />
                  Kunden
                </div>
              </div>

            </div>
            <div className='flex gap-x-8 items-center'>
              <a href='#contact' className='btn btn-lg flex items-center justify-center'>
                Contact me
              </a>
              <a href='#work' className='text-gradient btn-link'>
                My Portofolio
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;

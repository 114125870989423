import React from 'react'
import { FaGithub } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';

const WorkCards = ({ title, des, src }) => {
  return (
    <div className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r bg-gray-950 to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 
    hover:gray-900 transition-colors duration-1000">
      <div className='w-full h-[80%] overflow-hidden rounded-lg'>
        <img
          className="ueberblenden w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
          src={src}
          alt="src"
        />
      </div>

      <div className='w-full mt-5 flex flex-col gap-6'>
        <div className='flex items-center justify-between'>
          <h3 className='text-base uppercas text-gradient font-tertiary'>{title}</h3>
          <div className='flex gap-2'>
            <a href="https://github.com/GitHubQerim" className="icon github">
              <span className="tooltip"></span>
              <FaGithub />
            </a>
            <a href="mailto:office@qmwebtech.com" className="icon gmail">
              <span className="tooltip"></span>
              <SiGmail />
            </a>
          </div>
        </div>
        <div>
          <p className='text-sm tracking-wide mt-3 hover:text-gray-100 duration-300'>
            {des}
          </p>
        </div>
      </div>

    </div>
  )
}

export default WorkCards
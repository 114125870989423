import React from 'react';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';


const App = () => {
  return (
    <div className='bg-black bg-no-repeat bg-cover overflow-hidden'>
      <div className='content_wrap'>
        <Header />
        <Banner />
        <Nav />
        <About />
        <Services />
        <Work /> 
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;
